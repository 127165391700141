import React, { useRef, useEffect, useState, useCallback } from 'react';
import Webcam from 'react-webcam';
import * as S from './styles';
import * as tf from '@tensorflow/tfjs';
import * as cocossd from '@tensorflow-models/coco-ssd';
import '@tensorflow/tfjs-backend-webgl';
import TeachableMachineComponent from './TeachableMachineComponent';
// O componente TeachableMachineComponent deve ser importado aqui, como no seu código original.

export const ObjectDetection = () => {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [model, setModel] = useState(null);
  const [detectionMode, setDetectionMode] = useState('coco-ssd');

  // Função genérica para carregar um modelo baseado no modo de detecção.
  const loadModel = useCallback(async () => {
    switch (detectionMode) {
      case 'coco-ssd':
        try {
          await tf.backend('webgl');
          const cocoModel = await cocossd.load();
          setModel(cocoModel);
        } catch (error) {
          console.error('Erro ao carregar o modelo COCO-SSD:', error);
        }
        break;
      case 'teachable-machine':
        // Carregue o modelo Teachable Machine aqui, similar ao COCO-SSD.
        break;
      default:
        console.error('Modo de detecção desconhecido:', detectionMode);
    }
  }, [detectionMode]);

  // Função genérica para detectar objetos, independente do modelo atual.
  const detectObjects = useCallback(() => {
    if (webcamRef.current && canvasRef.current && model) {
      const video = webcamRef.current.video;

      const detect = async () => {
        if (video.readyState === video.HAVE_ENOUGH_DATA) {
          const predictions = await model.detect(video);
          renderPredictions(predictions);
          requestAnimationFrame(detect);
        } else {
          setTimeout(detect, 50);
        }
      };

      detect();
    }
  }, [model]);

  const renderPredictions = (predictions) => {
    const ctx = canvasRef.current.getContext('2d');
    ctx.canvas.width = webcamRef.current.video.videoWidth;
    ctx.canvas.height = webcamRef.current.video.videoHeight;
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  
    predictions.forEach((prediction) => {
      // Desestruture a previsão em variáveis separadas
      let [x, y, width, height] = prediction['bbox'];
      
      // Garanta que o retângulo não ultrapasse os limites do canvas
      if (x < 0) {
        width += x; // Reduz a largura se x for negativo
        x = 0; // Define x como 0 se for negativo
      }
      if (y < 0) {
        height += y; // Reduz a altura se y for negativo
        y = 0; // Define y como 0 se for negativo
      }
      
      // Garanta que o retângulo não ultrapasse a largura do canvas
      if (x + width > ctx.canvas.width) {
        width = ctx.canvas.width - x;
      }
      
      // Garanta que o retângulo não ultrapasse a altura do canvas
      if (y + height > ctx.canvas.height) {
        height = ctx.canvas.height - y;
      }
  
      // Agora desenhe o retângulo ajustado
      ctx.strokeStyle = 'rgba(0, 255, 0, 0.8)';
      ctx.lineWidth = 2;
      ctx.strokeRect(x, y, width, height);
  
      ctx.font = '18px Arial';
      ctx.fillStyle = 'rgba(0, 255, 0, 0.8)';
      const labelText = `${prediction['class']} ${(prediction['score'] * 100).toFixed(0)}%`;
      ctx.fillText(labelText, x, y > 10 ? y - 5 : y + 15); // Ajuste para não escrever fora do canvas
    });
  };
  

  // Efeito para carregar o modelo quando o modo de detecção muda.
  useEffect(() => {
    loadModel();
  }, [loadModel]);

  // Efeito para iniciar a detecção de objetos quando o modelo é carregado ou atualizado.
  useEffect(() => {
    if (model) {
      detectObjects();
    }
  }, [model, detectObjects]);

  return (
    <S.Container>
      <S.StyledTitle>Detecção de Objetos</S.StyledTitle>
      <S.SelectorWrapper>
        <label>
          Modo de Detecção:
          <select value={detectionMode} onChange={(e) => setDetectionMode(e.target.value)}>
            <option value="coco-ssd">COCO-SSD</option>
            <option value="teachable-machine">Teachable Machine</option>
          </select>
        </label>
      </S.SelectorWrapper>
      <br></br>
      <br></br>
      <S.VideoCanvasWrapper>
        {detectionMode === 'coco-ssd' && (
          <>
            <Webcam
              ref={webcamRef}
              width="600"
              height="400"
              mirrored={false}
              screenshotFormat="image/jpeg"
              videoConstraints={{ facingMode: 'environment' }}
            />
            <S.StyledCanvas ref={canvasRef} />
          </>
        )}
        {detectionMode === 'teachable-machine' && (
          <TeachableMachineComponent />
        )}
      </S.VideoCanvasWrapper>
      
    </S.Container>
  );
};
